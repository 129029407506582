import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// 解决报错
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// 针对 push 方法
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => err);
};
// 针对 replace 方法
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject);
  }
  return originalReplace.call(this, location).catch((err) => err);
};

const Launch = (resolve) => require(['@/views/Launch'], resolve);
const Index = (resolve) => require(['@/views/Index'], resolve);
const rulesIndex = (resolve) => require(['@/views/rulesIndex'], resolve);
const routes = [
  {
    path: '/',
    redirect: '/launch', // 重定向
    component: Launch,
  },
  {
    path: '/Launch',
    name: 'Launch',
    component: Launch,
    meta: {
      title: '',
    },
  },
  {
    path: '/index',
    name: 'Index',
    component: Index,
    meta: {
      title: '“V蓝北京”线上征集展示活动',
      keepAlive: true,
    },
  }
  // {
  //   path: '/rulesIndex',
  //   name: 'rulesIndex',
  //   component: rulesIndex,
  //   meta: {
  //     title: '“V蓝北京”线上征集展示活动',
  //     keepAlive: true,
  //   },
  // }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(savedPosition || { x: 0, y: 0 });
      }, 5);
    });
  },
});

export default router;
