<template>
  <div id="app" style="max-width: 500px;margin: 0 auto;">
    <!-- <router-view /> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
import { queryActivity } from '@/api/Api';
import { eventBus } from '@/common';

export default {
  data() {
    return {
      audioUrl: '',
      audioOpen: false,
      audioOpenClick: false
    };
  },
  watch: {
    $route: (to, from) => {
      document.title = to.meta.title || '';
    },
  },
  created() {
    // this.getActivity()
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
  },
};
</script>

<style lang="scss">
@import 'assets/scss/global';

#app {
  min-height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $theme-primary;
}

.aud {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.music {
  position: absolute;
  z-index: 99999;
  top: 8px;
  right: 8px;

  .audio-icon {
    font-size: 25px;
    color: #fff;
  }

  .audio-src {
    animation: animal 4s infinite linear;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
  }

  @keyframes animal {
    0% {
      transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
    }

    100% {
      transform: rotate(-360deg);
      -ms-transform: rotate(-360deg);
      -webkit-transform: rotate(-360deg);
    }
  }
}
</style>
