import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'normalize.css';

import Vant, { Lazyload } from 'vant';
import 'vant/lib/index.css';
import './assets/font/iconfont.css';
import { Toast } from 'vant';
import './assets/scss/global.scss';
import jssdk from  'weixin-js-sdk'

Vue.prototype.$wx = jssdk
Vue.prototype.$toast = Toast;

Vue.use(Vant).use(Lazyload);

Vue.config.productionTip = false;

// NodePlayer.load(() => {
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
// });
