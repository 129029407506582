import Vue from "vue";

// 获取url参数
let getUrlParams = name => {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let r = window.location.search.substr(1).match(reg);

  return r != null ? decodeURI(r[2]) : null;
};

// 跳转一个页面
let openUrl = url => {
  if (url) {
    postMessage({
      androidUrl: url,
      iosUrl: url,
      wxappUrl: url
    });
  }
};

// 阻止ios中回弹效果 start

let $el = "";
function addEventListenerTouchmove(e) {
  if (!e.isSCROLL) {
    e.preventDefault(); // 阻止默认事件(上下滑动)
  } else {
    // 需要滑动的区域
    let top = $el.scrollTop; // 对象最顶端和窗口最顶端之间的距离
    let scrollH = $el.scrollHeight; // 含滚动内容的元素大小
    let offsetH = $el.offsetHeight; // 网页可见区域高
    let cScroll = top + offsetH; // 当前滚动的距离

    // 被滑动到最上方和最下方的时候
    if (top === 0) {
      top = 1; // 0～1之间的小数会被当成0
    } else if (cScroll === scrollH) {
      $el.scrollTop = top - 0.1;
    }
  }
}
window.addEventListenerTouchmove = addEventListenerTouchmove;

let iosTrouchFn = el => {
  // 你需要滑动的dom元素
  let isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
  if (isIOS) {
    // el需要滑动的元素
    el.addEventListener("touchmove", function(e) {
      e.isSCROLL = true;
    });
    $el = el;
    document.body.addEventListener("touchmove", addEventListenerTouchmove, {
      passive: false
    }); // passive防止阻止默认事件不生效
  }
};

// 阻止ios中回弹效果 end

/* eslint-disable */
let callTel = (phone, name) => {
  callPhone(phone, name, name);
};

let isIos = () => {
  let u = navigator.userAgent;
  let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  return isiOS;
};

Vue.prototype.getUrlParams = getUrlParams;
Vue.prototype.openUrl = openUrl;
Vue.prototype.iosTrouchFn = iosTrouchFn;
Vue.prototype.callTel = callTel;
Vue.prototype.isIos = isIos;
