import request from '@/common/ajax';
import qs from 'qs';


// 下载
export function downLoad(data) {
  return request({
    url:
      'common/download',
    method: 'get',
    data
  });
}

// 微信转发配置
export function getWechatConfig(data) {
  return request({
    url:
      'jssdk.php?url=' +data,
    method: 'get',
    data: {},
  });
}
