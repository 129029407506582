import axios from 'axios';
import { Dialog } from 'vant';
import { getItem } from '@/common/storage';

axios.defaults.timeout = 3000000;
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;

//返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
};
//跨域请求，允许保存cookie
axios.defaults.withCredentials = true;

//HTTPrequest拦截
axios.interceptors.request.use(
  (config) => {
    const header = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    };
    if (['get', 'GET', 'delete', 'DELETE'].includes(config.method)) {
      header['Content-Type'] =
        'application/x-www-form-urlencoded; charset=UTF-8';
      config.params = config.data;
      delete config.data;
    }
    if (config.method === 'post' && config.headers['Content-Type']) {
      header['Content-Type'] = config.headers['Content-Type'];
    }
    let token = getItem('token');
    if (token) {
      header['token'] = token;
    }
    config.headers = {
      ...config.headers,
      ...header,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//HTTPresponse拦截
axios.interceptors.response.use(
  async (res) => {
    if (res.data.status == 401) {
      window.location.href = res.data.redirect_uri;
    }

    const { status } = res;
    var data = res.data;
    var message = (data && data.msg) || '未知错误';
    // const { error } = data;
    // if (typeof error == 'object') {
    //   message = error.msg || '未知错误';
    // }
    if (status === 404) {
      message = '接口资源不存在';
    }
    if (status === 401) {
      message = '暂无权限';
    }
    if (status === 500) {
      message = '服务器错误';
    }
    // 如果请求为非200否者默认统一处理
    if (status !== 200 && status !== 204) {
      Dialog.alert({
        message: message,
      });
      return Promise.reject(new Error(message));
    }
    return data;
  },
  (error) => {
    return Promise.reject(new Error(error));
  }
);

export default axios;
